import React from "react";

import Layout from "@components/Layout";

import * as styles from "@styles/components/NotFound/index.module.scss";

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <section className={styles.container}>
        <h1>404 - Page not Found</h1>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
